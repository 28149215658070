/* eslint-disable react/jsx-key */
import React from "react"

//logo de empresas clientes
import CasaRosello from "@components/sharedComponents/customersLogos/logos/CasaRossello.svg"
import CyC from "@components/sharedComponents/customersLogos/logos/C&C.svg"
import Plumas from "@components/sharedComponents/customersLogos/logos/Plumas.svg"
import Conexo from "@components/sharedComponents/customersLogos/logos/Conexo.svg"
import PlayNTrade from "@components/sharedComponents/customersLogos/logos/PlayNTrade.svg"
import Royal from "@components/sharedComponents/customersLogos/logos/Royal.svg"

//imagen articulos blog
import article1 from "@components/sharedComponents/articleBlog/images/instagram-dm-templates.jpg"
import article2 from "@components/sharedComponents/articleBlog/images/automatizar-respuestas-instagram.jpg"
import article3 from "@components/sharedComponents/articleBlog/images/ventas-a-traves-de-instagram.jpg"
import article4 from "@components/sharedComponents/articleBlog/images/generar-ventas-instagram.jpg"
import article5 from "@components/sharedComponents/articleBlog/images/expectativas-cliente.jpg"

// logos testimonios
import CostagasTes from "@components/sharedComponents/testimonies/images/logos/costagas.svg"

//foto testimonio desktop
import costagasDesktop from "@components/sharedComponents/testimonies/images/fotosDesktop/costagas.png"

//foto testimonio desktop
import costagasMobile from "@components/sharedComponents/testimonies/images/fotosMobile/costagas.png"

//image acordeon left
import ImageAccordionLeftOne from "@components/pageChannels/pageInstagram/images/accordionLeft/imageOne.webp"
import ImageAccordionLeftTwo from "@components/pageChannels/pageInstagram/images/accordionLeft/imageTwo.webp"
import ImageAccordionLeftThree from "@components/pageChannels/pageInstagram/images/accordionLeft/imageThree.webp"
import ImageAccordionLeftFour from "@components/pageChannels/pageInstagram/images/accordionLeft/imageFour.webp"

//iconos poteciamos operaciones
import imageConversionsOne from "@components/pageChannels/pageInstagram/conversions/images/imageOne.webp"
import imageConversionsTwo from "@components/pageChannels/pageInstagram/conversions/images/imageTwo.webp"
import imageConversionsThree from "@components/pageChannels/pageInstagram/conversions/images/imageThree.webp"
import imageConversionsFour from "@components/pageChannels/pageInstagram/conversions/images/imageFour.webp"

import imageVideo from "@components/sharedComponents/bannerVideo/image/imageInstagram.jpg"

import { TitleUnderlineInstagramOne, TitleUnderlineInstagramTwo } from "@components/pageChannels/components/intro/titleUnderlineInstagram"
import imageStartInstagram from "@components/pageChannels/components/intro/images/imageStartInstagram.webp"


const titleStart = (
  <p>
    Automatiza tu atención con IA y aumenta tus ventas por <TitleUnderlineInstagramOne underline="Instagram" />
  </p>
)
let data = {
  start: {
    title: titleStart,
    textbody: (
      <p>
        Optimiza tus procesos de atención, marketing y ventas por la red social con mayor influencia en los consumidores digitales. Genera una conexión personalizada con tus clientes en cada proceso de compra.
        <br /><br />
        Gestiona mejor tus chats, enruta la atención entre agentes, crea campañas de mensajes masivos y notificaciones, implementa chatbots con IA y mucho más.
      </p>
    ),
    image: imageStartInstagram,
    textbutton: "Habla con un experto",
    link: "/hablemos/",
  },
  customersDesktop: [
    CasaRosello,
    CyC,
    Plumas,
    Conexo,
    PlayNTrade,
    Royal
  ],
  customersTablet: {
    arrayOne: [CasaRosello, CyC, Plumas, Conexo, PlayNTrade, Royal],
  },
  articles: [
    {
      image: article1,
      title: "Instagram DM templates: + 10 plantillas gratis",
      textbody:
        "Conoce qué es Instagram DM templates y + 10 ejemplos para estrechar tu relación con tus clientes. ¡ENTRA AQUÍ y eleva tu tasa de retención!",
      link: "https://beexcc.com/blog/instagram-dm-templates/",
      card: true,
    },
    {
      image: article2,
      title: "7 claves para automatizar respuestas en Instagram",
      textbody:
        "Te enseñamos a cómo automatizar respuestas en Instagram para que puedas tener una mejor atención y vender más por esta app.",
      link: "https://beexcc.com/blog/automatizar-respuestas-instagram/",
      card: true,
    },
    {
      image: article3,
      title: "¿Cómo aumentar las ventas a través de Instagram con chatbot?",
      textbody:
        "Te enseñamos a cómo aumentar las ventas a través de Instagram gracias a la integración de un chatbot online.",
      link: "https://beexcc.com/blog/ventas-a-traves-de-instagram/",
    },
    {
      image: article4,
      title: "Aumenta tus ganancias y aprende a generar ventas en Instagram",
      textbody:
        "Descubre cómo generar ventas en Instagram y empieza a reforzar las estrategias para aumentar ventas de tu negocio digital.",
      link: "https://beexcc.com/blog/generar-ventas-instagram/",
    },
    {
      image: article5,
      title: "¿Cómo mejorar las expectativas de un cliente? 7 consejos",
      textbody:
        "Descubre las mejores prácticas para mejorar las expectativas de un cliente con esta guía completa. ¡ENTRA AQUÍ y cautiva a tus clientes!",
      link: "https://beexcc.com/blog/expectativas-cliente/",
    },
  ],
  contacts: {
    title: "Mejora las interacciones con tus clientes por Instagram",
    textbody: "Cuéntanos cómo podemos potenciar y automatizar tus operaciones de atención al cliente para que nuestro equipo pueda brindarte feedback importante.",
    href: "/hablemos/",
    btn: "Quiero una asesoría gratuita",
  },
  footer: {
    product: "PRODUCTOS",
    productDesktopOne: "Conversations: Atención al cliente omnicanal y chatbots.",
    productMobileOne: "Conversations",
    linkone: "/conversations/",
    productDesktopTwo: " Contact Center: Software en la nube para Contact Center.",
    productMobileTwo: "Contact Center",
    linktwo: "/contact-center/",
    productDesktopFour: "Validation: Software de validación de teléfono.",
    productMobileFour: "Validation",
    linkfour: "/validation/",

    solution: "SOLUCIONES",
    solutionOne: "Atención al cliente",
    linkfive: "/atencion-al-cliente/",
    solutionTwo: "Telemarketing",
    linksix: "/marketing-ventas/",
    solutionThree: "Contact Center",
    linkseven: "/contact-center/",
    solutionFour: "Cobranzas",
    linkeight: "/software-cobranzas/",
    solutionFive: "WhatsApp",
    linkwhatsapp: "/whatsapp-business-api/",
    linkchatbots: "/chatbots-online/",
    solutionSix: "Chatbots",
    linkinstagram: "/chatbot-instagram/",
    solutionSeven: "Instagram",
    linkwebchat: "/chat-en-vivo-web/",
    solutionEight: "WebChat",

    company: "EMPRESA",
    companyOne: "Nosotros",
    linknine: "/nosotros/",
    companyTwo: "Casos de éxito",
    linkten: "/casos-de-exito/",
    companyThree: "Blog",
    linkeleven: "https://beexcc.com/blog/",
    companyFour: "Hablemos",
    linktwelve: "/hablemos/",
    companySix: "Documentación",
    linkdocs: "https://beexcc.com/docs/",
    companyFive: "Recursos",
    linktthirteen: "/recursos/",

    blog: "BLOG",
    blogone: "¿Cómo elegir el mejor software para call center?",
    linkblogone: "https://beexcc.com/blog/mejor-software-call-center/",

    blogtwo: "Plataforma Omnicanal: Qué es y cómo funciona",
    linkblogtwo: "https://beexcc.com/blog/plataforma-omnicanal/",

    blogthree: "¿Qué es WhatsApp Business API? - Guía completa 2023",
    linkblogthree: "https://beexcc.com/blog/que-es-whatsapp-business-api/",

    blogfour:
      "¿Cómo elegir el mejor chatbot? 5 características que debe cumplir",
    linkblogfour: "https://beexcc.com/blog/mejor-chatbot/",

    blogfive: "9 características que las herramientas de telemarketing",
    linkblogfive: "https://beexcc.com/blog/herramientas-telemarketing/",

    masblog: "Conoce más sobre nuestro blog",
    linkmasblog: "https://beexcc.com/blog/",

    address: "Jirón Pachacutec 1315, Of. 503, Jesús María, Lima, Perú",
    phone: "(+511) 277-4188",
    mail: "hi@beexcc.com",
    copyright: "Copyright© 2024 Beex Perú. Todos los derechos reservados.",
    privacyPolicy: "Política de Privacidad",
    privacyPolicyHref: "/politicas-privacidad",
    cookiesPolicy: "Política de Cookies",
    cookiesPolicyHref: "/politicas-cookies",
  },
  testimonies: [
    {
      image: CostagasTes,
      title: "Con Beex, ya tenemos trazabilidad real de los leads que llegan por Instagram",
      textbody:
        "Costagas, compañía con más de 40 años en el sector energético, comercializa sus servicios de consumo masivo con Beex. Logrando mejorar sus ventas en 1.3x",
      name: "Natalia Pachas",
      post: "Jefe de Marketing",
      link: "/casos-de-exito/costagas/",
      imgDesktop: costagasDesktop,
      imgMobile: costagasMobile,
      metrics: [
        {
          metric: "1.3x",
          descripcion: "aumento de ventas digitales",
          color: "#FFB800",
        },
        {
          metric: "<1 minuto",
          descripcion: "tiempo de primera respuesta",
          color: "#5831D8",
        },
        {
          metric: "-40%",
          descripcion: "reducción de abandono",
          color: "#00CA56",
        },
      ],
    },
  ],
  accordionRight: [
    {
      title: "Integra chatbots con IA",
      description: "<p>Implementa chatbots inteligentes para responder instantáneamente a preguntas frecuentes, guiar a los usuarios a través de procesos de compra, o incluso resolver problemas comunes sin intervención humana las 24 horas del día.</p>",
      image: ImageAccordionLeftOne
    },
    {
      title: "Mejora tus resultados de tus Ads",
      description: "<p>Implementar un autoservicio con chatbots, IA y menús interactivos, para manejar el alto volumen de atención generado por la publicidad digital. Mejora la gestión de nuevos leads y mejora la conversión de los anuncios mediante respuestas rápidas.</p>",
      image: ImageAccordionLeftTwo
    },
    {
      title: "Respuestas automáticas en tus historias",
      description: "<p>Configura automatizaciones para enviar mensajes personalizados a las reacciones y mensajes que llegan vía Direct Message por tus historias. Hazlo al instante con chatbots e IA, y evita el abandono de tus compras por falta de respuesta.</p>",
      image: ImageAccordionLeftThree,
    },
    {
      title: "Integraciones y workflows",
      description: "<p>Crea flujos de mensajes automáticos que optimicen el contacto con tus seguidores. Intégralos con tu CRM o CMS, como Salesforce, HubSpot, Shopify, VTEX y más, para una gestión de contactos sin fisuras, permitiendo la automatización de notificaciones sobre compras o seguimientos.</p>",
      image: ImageAccordionLeftFour
    },
  ],
  bannerVideo: {
    title: "6 tips sencillos para aumentar las ventas en Instagram",
    description: "<p>¿Buscas aumentar tus ventas por Instagram? No es necesario contar con miles de seguidores. Lo principal es que brindes una buena atención a los clientes para que se queden contigo.<br /><br />Nuestra analista de marketing, María Vasquez, nos cuenta cuáles son los pasos que debes seguir para que no solo puedas aumentar tus ventas por Instagram, sino también logres fidelizar.</p>",
    image: imageVideo
  },
  conversions: [
    {
      title: "Gestión entre canales",
      img: imageConversionsOne,
      text:
        "Unifica Instagram con otros canales como WhatsApp, teléfono, SMS o correo, y enruta la atención entre puntos de contacto si el cliente lo prefiere. Maneja todo el historial de mensajes desde un solo hilo de conversación, independientemente de dónde se originó el contacto.",
    },
    {
      title: "Asistencia en la compra",
      img: imageConversionsTwo,
      text:
        "Evita el abandono de una compra por una falta de atención y mejora la asistencia durante el proceso de venta al permitir interacciones en tiempo real y personalizadas con chatbots conversacionales o agentes en tiempo real.",
    },
    {
      title: "Integración con herramientas externas",
      img: imageConversionsThree,
      text:
        "Integra Instagram a tus otras herramientas internas como CRM o ERP para crear un ecosistema de datos conectado que potencie la personalización, mejore el seguimiento del cliente y optimice la eficiencia de la comunicación desde un solo lugar.",
    },
    {
      title: "Analiza tus interacciones",
      img: imageConversionsFour,
      text:
        "Utiliza dashboards y crea reportes de todas tus interacciones con tus clientes por Instagram para identificar comportamientos de los usuarios, y tomar decisiones basadas en datos para optimizar tu estrategia de marketing y servicio al cliente.",
    },
  ],
}

export default data
